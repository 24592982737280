<template>
  <v-app id="error">
    <v-app-bar
      :clipped-left="primaryDrawer.clipped"
      app
    >
      <v-toolbar-title>AVA Webinar - Oops!</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <div class="pa-4">
        <slot />
      </div>
    </v-main>

    <v-footer
      :inset="footer.inset"
      app
    >
      <span class="px-4">&copy; {{ new Date().getFullYear() }} Avantgarde</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawers: ['Default (no property)', 'Permanent', 'Temporary'],
    primaryDrawer: {
      clipped: false,
    },
    footer: {
      inset: false,
    },
    right: null,
  }),
};
</script>
